<template>
    <v-row class="row--sm">
        <v-col cols="12" lg="5" class="d-none d-lg-block" v-if="banner">
            <contents-card-banner v-for="(value, index) in banner?.slides" :key="index" :value="value" />
        </v-col>

        <v-col cols="12" :lg="banner ? 7 : 12">
            <!-- <v-row v-if="!isMobile" class="row--sm" v-masonry> -->
            <v-row class="row--sm">
                <v-col cols="12" lg="6">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <!-- 나의 추천태그 -->
                            <contents-card-tags />
                            <!-- // 나의 추천태그 -->
                        </v-col>
                        <!-- PC 광고 카드 -->
                        <template v-if="!isMobile">
                            <template v-for="(advertisement, index) in advertisements">
                                <v-col cols="12" :key="index">
                                    <contents-card-advertisement v-bind="{ value: advertisement }" />
                                </v-col>
                            </template>
                        </template>
                        <!-- // PC 광고 카드 -->
                    </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-row>
                        <v-col cols="12">
                            <contents-card-rank />
                        </v-col>
                        <!-- PC 주변 업체 -->
                        <template v-if="!isMobile">
                            <template v-for="(store, index) in stores">
                                <v-col cols="12" :key="index">
                                    <recommend-store-item v-bind="{ value: store }" />
                                </v-col>
                            </template>
                        </template>
                        <!-- // PC 주변 업체 -->
                    </v-row>
                </v-col>
                <template v-if="isMobile">
                    <!-- Mobile 광고 카드 -->
                    <template v-for="(advertisement, index) in advertisements">
                        <v-col cols="12" :key="index">
                            <contents-card-advertisement v-bind="{ value: advertisement }" />
                        </v-col>
                    </template>
                    <!-- // Mobile 광고 카드 -->

                    <!-- Mobile 주변 업체 -->
                    <template v-for="(store, index) in stores">
                        <v-col cols="12" :key="index">
                            <recommend-store-item v-bind="{ value: store }" />
                        </v-col>
                    </template>
                    <!-- // Mobile 주변 업체 -->
                </template>
                <!-- <template v-for="value in displays">
                    <v-col cols="12" lg="6" :key="value._id">
                        <contents-card-story v-bind="{ value }" v-on="{ load }" />
                    </v-col>
                </template> -->
            </v-row>
        </v-col>
        <contents-load-banners v-model="banner" />
        <!-- <contents-load-displays v-model="displays" @input="load" /> -->
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import api from "@/api";
import detect from "@/plugins/detect";
import ContentsCardBanner from "./contents/contents-card-banner.vue";
import ContentsCardRank from "./contents/contents-card-rank.vue";
import ContentsCardStory from "./contents/contents-card-story.vue";
import ContentsCardTags from "./contents/contents-card-tags.vue";
import ContentsCardAdvertisement from "./contents/contents-card-advertisement.vue";
import ContentsLoadBanners from "./contents/contents-load-banners.vue";
import ContentsLoadDisplays from "./contents/contents-load-displays.vue";
import RecommendStoreItem from "./main-recommend-stores/recommend-store-item.vue";
export default {
    components: {
        ContentsCardBanner,
        ContentsCardRank,
        ContentsCardTags,
        ContentsCardAdvertisement,
        ContentsLoadBanners,
        ContentsLoadDisplays,
        RecommendStoreItem,
        ContentsCardStory,
    },
    data: () => ({
        banner: null,

        stores: [],
        advertisements: [],
    }),
    computed: {
        ...mapState("location", ["bounds", "isGPSAllowed"]),
        ...mapGetters("location", ["geolocation"]),
        isMobile() {
            return detect === "mobile";
        },
    },
    methods: {
        ...mapActions("location", ["getCurrentLocation"]),
        init() {
            this.getCurrentLocation();
            this.searchRecommendStores();
            this.searchAdvertisements();
        },
        async searchRecommendStores() {
            try {
                let { geolocation: near, bounds } = this;

                let params = {};
                
                if (this.isGPSAllowed) {
                    params.mapLimit = 4;
                    params.near = near;
                    params.bounds = this.getBoundsWithin200KM(near?.coordinates);
                }

                let { stores } = await api.v1.shop.stores.gets({
                    headers: { skip: 0, limit: 4, sort: JSON.stringify({ index: -1 }) },
                    params,
                });

                this.stores = stores;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async searchAdvertisements() {
            try {
                let { advertisements } = await api.v1.bannerAdvertisements.gets({
                    headers: { limit: 4 },
                    params: { isDeleted: false },
                });

                this.advertisements = advertisements;
            } catch (error) {
                this.$handleError(error);
            }
        },
        getBoundsWithin200KM(location) {
            const [lng, lat] = location;
            const deltaLat = 200 / 111.32;
            const deltaLng = 200 / (111.32 * Math.cos(lat * (Math.PI / 180)));

            const w = lng - deltaLng;
            const e = lng + deltaLng;
            const n = lat + deltaLat;
            const s = lat - deltaLat;

            return { w, s, e, n };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        isGPSAllowed() {
            this.searchRecommendStores();
        },
    },
};
</script>

<style>
</style>