import axios from "@/plugins/axios";

let url = (data) => "/api/v1/me/reviews/${_review}/images".interpolate(data);

export default {
    gets(data) {
        return axios.get(url(data), data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url(data)}/${data._id}`).then((result) => result.data);
    },
    post({ _review, index }, image) {
        const headers = { "Content-Type": "multipart/form-data" };
        const formData = new FormData();
        if (_review) formData.append("_review", _review);
        if (index !== undefined) formData.append("index", index);
        formData.append("image", image);
        return axios.post(`${url({ _review })}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url(data)}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
