var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-logout",
    attrs: {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.66666 2.49999C1.66666 2.03975 2.03975 1.66666 2.49999 1.66666H10.8299V3.33332H3.33332V16.6667H10.8333V18.3333H2.49999C2.03975 18.3333 1.66666 17.9602 1.66666 17.5V2.49999Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.75 5.0715L18.0893 9.41076C18.4147 9.7362 18.4147 10.2638 18.0893 10.5893L13.75 14.9285L12.5715 13.75L16.3215 10L12.5715 6.25001L13.75 5.0715Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.83334 9.16666H16.8333V10.8333H5.83334V9.16666Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }