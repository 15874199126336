import axios from "@/plugins/axios";

let url = "/api/v1/shop/categories";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data.code}`, data).then((result) => result.data);
    },
};
