import axios from "@/plugins/axios";
import process from "./process";

let url = "/api/v1/shop/orders";

export default {
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    process,
};
