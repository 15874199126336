import axios from "@/plugins/axios";

let url = "/api/v1/center/notifications";

export default {
    getNotifications(data){
        return axios.get(url, data).then(result => result.data);
    },
    getNotification(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}
