import { SHOP_COUPON_UNITS } from "../constants";
import { initUsage } from "./common.inits";

export const initUserCoupon = (coupon = {}) => ({
    ...coupon,

    _user: coupon._user ?? null,
    _coupon: coupon._coupon ?? null,

    name: coupon.name ?? null,
    unit: coupon.unit ?? SHOP_COUPON_UNITS.AMOUNT.value,
    round: coupon.value ?? 1,
    value: coupon.value ?? 0,
    limit: coupon.limit ?? null,
    isUsed: coupon.isUsed ?? false,
    isExpired: coupon.isExpired ?? false,
    isExcluded: coupon.isExcluded ?? false,

    usage: initUsage(coupon.usage),

    usedAt: coupon.usedAt ?? null,
    createdAt: coupon.createdAt ?? null,
});
