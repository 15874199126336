<template>
    <main-display-categories v-bind="{ code }">
        <template #default="{ categories }">
            <v-chip-group column>
                <template v-for="category in categories">
                    <v-chip small outlined link color="grey darken-4" :key="category._id" :to="{ path: '/shop/products/', query: { groups: code, category: category.code } }">#{{ category.name }}</v-chip>
                </template>
            </v-chip-group>
        </template>
    </main-display-categories>
</template>

<script>
import MainDisplayCategories from "@/components/client/main/main-display-categories.vue";

export default {
    components: {
        MainDisplayCategories,
    },
    props: {
        code: { type: String, default: null },
    },
};
</script>

<style>
</style>