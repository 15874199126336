<template>
    <div class="header-icon-menu">
        <v-row class="row--xs">
            <v-col cols="auto">
                <router-link :to="accessToken ? `/minishop/${_user}` : '/login'" class="header-icon-menu__link header-icon-menu__link--home">
                    <div class="header-icon" />
                    <span class="header-icon-text">미니홈</span>
                </router-link>
            </v-col>
            <v-col cols="auto">
                <main-header-notification />
            </v-col>
            <v-col cols="auto">
                <router-link class="header-icon-menu__link header-icon-menu__link--shopping-bag" to="/cart">
                    <div class="header-icon" />
                    <span class="header-icon-text">장바구니</span>
                </router-link>
            </v-col>
            <v-col cols="auto">
                <header-search />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MainHeaderNotification from "../main-header-notification.vue";
import headerSearch from "./header-search.vue";
export default {
    components: { headerSearch, MainHeaderNotification },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        _user() {
            return this.$store.state.payload._user;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
