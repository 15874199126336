export const initBandBanner = (bandBanner = {}) => ({
    ...bandBanner,

    code: bandBanner?.code || null,
    name: bandBanner?.name || null,
    url: bandBanner?.url || null,

    display: {
        ...(bandBanner?.display || {}),
        enabled: bandBanner?.display?.enabled || false,
        hasPeriod: bandBanner?.display?.hasPeriod || false,
        startedAt: bandBanner?.display?.startedAt || null,
        endedAt: bandBanner?.display?.endedAt || null,
    },
});
