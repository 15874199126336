<template>
    <v-menu v-model="shows" :close-on-content-click="false" left nudge-top="12px" nudge-right="0px">
        <template #activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" class="header-icon-menu__link header-icon-menu__link--search">
                <div class="header-icon" />
                <span class="header-icon-text">검색</span>
            </button>
        </template>

        <v-row align="center" no-gutters>
            <v-col cols="auto">
                <v-btn tile small icon text @click="search">
                    <v-img max-width="20" src="/images/icon/icon-search.svg" />
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-text-field v-model="searchValue" v-bind="{ ...attrs_input }" :outlined="false" solo flat autofocus dense placeholder="검색어를 입력해주세요" v-on="{ blur }" @keydown.enter="search" />
            </v-col>
        </v-row>
    </v-menu>
</template>

<script>
import api from "@/api";
import { attrs_input, COMMON_LOG_TYPES } from "@/assets/variables";
import { mapMutations } from "vuex";

export default {
    data: () => ({
        shows: false,

        attrs_input,
        searchValue: null,
    }),
    computed: {
        searches() {
            return this.$store.state.searches;
        },
        isSearchedAgain() {
            if (this.$route.query["searchValue"]) {
                return this.searchValue === this.$route.query["searchValue"];
            }
            return false;
        },
    },
    methods: {
        ...mapMutations(["setSearches"]),
        blur() {
            this.shows = false;
        },
        async search() {
            try {
                if (this.searchValue && !this.isSearchedAgain) {
                    await api.v1.shop.logs.saving({
                        type: COMMON_LOG_TYPES.SEARCH.value,
                        value: this.searchValue,
                    });

                    if (this.searches.length > 4) this.searches.shift();
                    this.setSearches({ searches: [...this.searches, this.searchValue] });

                    this.$router.push({
                        path: "/search",
                        query: {
                            searchValue: this.searchValue,
                        },
                    });
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
    padding-right: 22px;
    border-radius: 999px;
}
</style>