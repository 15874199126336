var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-shopping-bag",
    attrs: {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.99999 0.5C8.02166 0.5 6.41791 2.10375 6.41791 4.08208V4.1418H4.99253C3.76844 4.1418 2.77612 5.13412 2.77612 6.3582V17.2836C2.77612 18.5077 3.76844 19.5 4.99253 19.5H15.0075C16.2316 19.5 17.2239 18.5077 17.2239 17.2836V6.3582C17.2239 5.13412 16.2316 4.1418 15.0075 4.1418H13.5821V4.08208C13.5821 2.10375 11.9783 0.5 9.99999 0.5ZM8.11937 4.08208C8.11937 3.04344 8.96135 2.20146 9.99999 2.20146C11.0386 2.20146 11.8806 3.04344 11.8806 4.08208V4.1418H8.11937V4.08208ZM4.47758 6.3582C4.47758 6.07381 4.70813 5.84326 4.99253 5.84326H6.41791V8.10323C6.41791 8.36357 6.62895 8.57461 6.88928 8.57461H7.64799C7.90832 8.57461 8.11937 8.36357 8.11937 8.10323V5.84326H11.8806V8.10323C11.8806 8.36357 12.0916 8.57461 12.352 8.57461H13.1107C13.371 8.57461 13.5821 8.36357 13.5821 8.10323V5.84326H15.0075C15.2919 5.84326 15.5224 6.0738 15.5224 6.3582V17.2836C15.5224 17.568 15.2919 17.7985 15.0075 17.7985H4.99253C4.70813 17.7985 4.47758 17.568 4.47758 17.2836V6.3582Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }