<template>
    <v-card tile flat class="recommend-card text-center" :href="`/minishop/${value._id}`">
        <div>
            <!-- image -->
            <v-card tile flat :img="bannerSrc">
                <v-responsive :aspect-ratio="1 / 1" />
            </v-card>
            <!-- // image -->

            <!-- <v-avatar size="120" class="border mb-30px">
                <v-img :src="value.thumb ? value.thumb.src : '/images/minishop/profile-default.png'"  class="mx-auto"/>
            </v-avatar> -->
            <v-sheet class="pa-18px">
                <h3 class="txt txt--dark font-weight-bold mb-8px">{{ value?.nickname }}</h3>
                <p class="txt txt--sm txt--dark mb-4px">{{ value.address1 }} {{ value.address2 }}</p>
                <p class="txt txt--sm txt--dark">{{ value?.tel }}</p>
                <div class="mt-8px">
                    <v-row justify="center" class="row--xxs">
                        <template v-for="(tag, index) in value?.mini?.tag">
                            <v-col v-if="index < 3" :key="index" cols="auto">
                                <v-chip v-bind="{ ...chip_secondary }" x-small>
                                    {{ tag }}
                                </v-chip>
                            </v-col>
                        </template>
                    </v-row>
                </div>
            </v-sheet>
        </div>
    </v-card>
</template>

<script>
import { chip_secondary } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        chip_secondary,
    }),
    computed: {
        bannerSrc() {
            let { mainBanner, banner } = this.value;
            if(mainBanner) return mainBanner.src;
            if(banner) return banner.src;
            return '/images/minishop/skin-default.jpg'
        }
    }
};
</script>

<style lang="scss" scoped>
.recommend-card {
    // border: 1px solid var(--v-grey-lighten3);
    // &__img {
    //     border: 1px solid var(--v-grey-lighten3);
    //     border-radius: 999px;
    // }
}
</style>