import { render, staticRenderFns } from "./main-header-notification.vue?vue&type=template&id=77d1d1d0&scoped=true&"
import script from "./main-header-notification.vue?vue&type=script&lang=js&"
export * from "./main-header-notification.vue?vue&type=script&lang=js&"
import style0 from "./main-header-notification.vue?vue&type=style&index=0&id=77d1d1d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d1d1d0",
  null
  
)

export default component.exports