<template>
    <header class="header">
        <!-- S: PC -->
        <div class="header__inner">
            <div class="header-body">
                <v-container class="container--lg">
                    <div class="header-contents">
                        <main-logo />
                        <div class="header-user-menu">
                            <ul>
                                <!-- <li v-if="!accessToken">
                                    <router-link class="link" to="/mypage/order-list">
                                        <icon-person />
                                        <span class="header-user-menu__txt">비회원주문조회</span>
                                    </router-link>
                                </li> -->
                                <li v-if="accessToken">
                                    <div class="link cursor-pointer" @click="logout">로그아웃</div>
                                </li>
                                <li v-else>
                                    <router-link class="link" to="/login">로그인</router-link>
                                    <!-- <v-alert type="error">마이페이지는 로그인 후 이용하실 수 있습니다.</v-alert> -->
                                </li>

                                <li v-if="accessToken">
                                    <router-link class="link" to="/mypage/dashboard">
                                        <!-- <span class="header-user-menu__txt">마이페이지</span> -->
                                        마이페이지
                                    </router-link>
                                </li>
                                <li v-else>
                                    <router-link class="link" to="/join">회원가입</router-link>
                                </li>
                                <li>
                                    <router-link class="link" to="/center/notice">고객센터</router-link>
                                    <ul class="header-user-menu__sub">
                                        <li><router-link to="/center/notice">공지사항</router-link></li>
                                        <li><router-link to="/center/faq?type=MEMBERSHIP">자주 묻는 질문</router-link></li>
                                        <li><router-link to="/center/questions">1:1 문의</router-link></li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link class="link" to="/sub/business/about">비즈니스</router-link>
                                    <ul class="header-user-menu__sub">
                                        <li><router-link to="/sub/business/about">회사소개</router-link></li>
                                        <li><router-link to="/sub/business/contact">입점신청</router-link></li>
                                        <li><router-link to="/sub/business/terms">이용약관</router-link></li>
                                        <li><router-link to="/sub/business/privacy-policy">개인정보 처리방침</router-link></li>
                                        <li><router-link to="/sub/business/site-guide">이용안내</router-link></li>
                                    </ul>
                                </li>
                                <!-- <li>
                                    <router-link class="link" to="/mypage/likes">
                                        <span class="material-icons-outlined">favorite_border</span>
                                    </router-link>
                                </li> -->
                                <!-- <li>
                                    <main-cart-button to="/mypage/cart">
                                        <template v-slot:default="{ carts }">
                                            <em class="badge">{{ carts.length }}</em>
                                            <span>
                                                <v-badge :value="carts.length != 0" dot bordered overlap color="primary">
                                                    <icon-shopping-bag />
                                                </v-badge>
                                            </span>
                                        </template>
                                    </main-cart-button>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                    <v-divider />
                </v-container>
            </div>

            <div class="header-foot">
                <v-container class="container--lg">
                    <v-row no-gutters justify="center">
                        <v-col cols="auto">
                            <main-header-gnb />
                        </v-col>
                    </v-row>

                    <header-icon-menu />
                </v-container>
            </div>
        </div>
        <!-- E: PC -->

        <!-- S: Mobile -->
        <!-- mobile-header -->
        <div class="mobile-header">
            <main-logo />
            <!-- <ul class="mobile-header__customer">
                <li>
                    <router-link v-if="!accessToken" to="/login">로그인</router-link>
                    <a href="#" v-else @click="logout">로그아웃</a>
                </li>
                <li>
                    <router-link v-if="!accessToken" to="/mypage/order-list">비회원주문조회</router-link>
                    <router-link v-else to="/mypage/myinfo">마이페이지</router-link>
                </li>
            </ul> -->
            <button class="header-icon-menu__link header-icon-menu__link--search mobile-header__search">
                <div class="header-icon" />
                <span class="header-icon-text">검색</span>
            </button>
            <!-- <button class="mobile-header__search"><v-img src="/images/icon/icon-search.svg" max-width="20" /><span class="d-sr-only">상품검색창열기</span></button> -->
            <!-- <main-shop-categories-mobile /> -->
            <main-header-notification class="mobile-header__notification" />
        </div>
        <!-- mobile-searchbar -->
        <mobile-search-bar />
        <!-- mobile-hamburger -->
        <div class="mobile-hamburger">
            <button type="button" aria-pressed="false" class="mobile-hamburger__btn">
                <v-sheet width="24" height="24" class="position-relative">
                    <span class="mobile-hamburger__line"></span>
                </v-sheet>
                <div class="font-size-10 line-height-18">메뉴</div>
            </button>
        </div>
        <!-- mobile-sidebar -->
        <div class="mobile-sidebar">
            <div class="mobile-sidebar-head">
                <router-link to="/" class="mr-12px">
                    <v-img src="/images/icon/icon-home-white.svg" alt="홈으로 이동" />
                </router-link>
                <div class="mobile-sidebar__info">
                    <router-link v-if="!accessToken" class="mobile-sidebar__member" to="/login">로그인</router-link>
                    <a href="#" v-else @click="logout" class="mobile-sidebar__member">로그아웃</a>
                </div>
                <button class="mobile-sidebar__close" type="button">
                    <v-icon class="white--text">mdi-close</v-icon>
                    <span class="d-sr-only">모바일 메뉴바 닫기</span>
                </button>
            </div>

            <div class="mobile-sidebar-body">
                <main-header-gnb mobile />
            </div>
            <div class="mobile-sidebar-foot">
                <main-header-slide />
                <v-row justify="center" class="row--sm font-">
                    <v-col cols="auto" v-if="accessToken">
                        <div class="link cursor-pointer" @click="logout">로그아웃</div>
                    </v-col>
                    <v-col cols="auto" v-else>
                        <router-link class="link" to="/login">로그인</router-link>
                    </v-col>
                    <v-col cols="auto" v-if="accessToken">
                        <router-link class="link" to="/mypage/dashboard">마이페이지</router-link>
                    </v-col>
                    <v-col cols="auto" v-else>
                        <router-link class="link" to="/join">회원가입</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link class="link" to="/center/notice">고객센터</router-link>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="mobile-header-dimmed"></div>
        <!-- E: Mobile -->
    </header>
</template>

<script>
import { DISPLAY_CODES, EXHIBITION_GROUPS, EVENT_STATUS, attrs_input } from "@/assets/variables";

import IconSearch from "@/components/client/icons/icon-search.vue";
import IconPerson from "@/components/client/icons/icon-person.vue";
import IconShoppingBag from "@/components/client/icons/icon-shopping-bag.vue";
import IconLogout from "@/components/client/icons/icon-logout.vue";

import MainLogo from "./main-logo.vue";

import MainHeaderSlide from "@/components/client/inc/main-header-slide.vue";
import MainShopCategories from "../main/main-shop-categories.vue";
import MainShopCategoriesMobile from "../main/main-shop-categories-mobile.vue";

import MainHeaderGnb from "@/components/client/inc/main-header-gnb.vue";
import MainHeaderNotification from "@/components/client/inc/main-header-notification.vue";
import MobileSearchBar from "@/components/client/inc/main-header/mobile-search-bar.vue";
import HeaderIconMenu from "./main-header/header-icon-menu.vue";
import MainProductsPopularCategories from "../main/main-products-popular/main-products-popular-categories.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        IconSearch,
        IconPerson,
        IconShoppingBag,
        IconLogout,

        MainLogo,

        MainHeaderSlide,
        MainShopCategories,
        MainShopCategoriesMobile,

        MainHeaderGnb,
        MainHeaderNotification,
        MobileSearchBar,
        HeaderIconMenu,
        MainProductsPopularCategories,
    },
    data: () => ({
        searchValue: null,

        groups,
        DISPLAY_CODES,
        EXHIBITION_GROUPS,
        EVENT_STATUS,
        attrs_input,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const { searchValue } = this.$route?.query || {};
            this.searchValue = searchValue;

            this.$nextTick(function () {
                this.header();
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        search() {
            this.$router.push(`/shop/products?searchValue=${this.searchValue || ""}`);
        },

        // header
        header: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    hamburgerCreate.bind(this)();
                }
            }

            // Mobile Header
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                searchBtn = document.querySelector(".mobile-header__search"),
                closeSidebar = document.querySelector(".mobile-sidebar__close"),
                closeSearchbar = document.querySelector(".mobile-searchbar__close"),
                dimmed = document.querySelector(".mobile-header-dimmed"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");

            hamburgerBtn.addEventListener("click", hamburgerDestroy);
            searchBtn.addEventListener("click", searchDestroy);
            closeSidebar.addEventListener("click", hamburgerCreate.bind(this));
            closeSearchbar.addEventListener("click", searchCreate);
            dimmed.addEventListener("click", hamburgerCreate.bind(this));
            dimmed.addEventListener("click", searchCreate);
            mobileCategory.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });

            // Mobile Hamburger
            function hamburgerCreate() {
                this.closeSidebar();
            }
            function hamburgerDestroy() {
                hamburgerBtn.setAttribute("aria-pressed", "true");
                header.setAttribute("data-sidebar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
            // Mobile Search
            function searchCreate() {
                // HTML.removeAttribute("data-scroll");
                header.setAttribute("data-searchbar", "closed");
                header.setAttribute("data-dimmed", "closed");
            }
            function searchDestroy() {
                // HTML.setAttribute("data-scroll", "false");
                header.setAttribute("data-searchbar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }

            // Mobile
            function toggleAccordion(el) {
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
                var targetLi = el.currentTarget.parentNode.classList;
                // var target = el.currentTarget;

                el.preventDefault();
                if (targetLi.contains("opened")) {
                    targetLi.remove("opened");
                } else {
                    mobileCategory.forEach(function (el) {
                        el.parentNode.classList.remove("opened");
                    });
                    targetLi.add("opened");
                }
            }
        },
        closeSidebar() {
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link");
            let attr = header.getAttribute("data-dimmed");
            if (attr !== null) {
                hamburgerBtn.setAttribute("aria-pressed", "false");
                header.setAttribute("data-sidebar", "closed");
                header.setAttribute("data-dimmed", "closed");
                mobileCategory.forEach((element) => {
                    element.setAttribute("data-toggle", "closed");
                });
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        _user() {
            return this.$store.state.payload._user;
        },
    },
    watch: {
        "$route.query.searchValue"() {
            this.init();
        },
        "$route.query"() {
            this.closeSidebar();
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
    .header-icon-menu {
        ::v-deep() {
            .v-badge__badge {
                min-width: initial;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 10px;
            }
        }
    }
}
</>
