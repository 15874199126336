<template>
    <draggable :value="localValue" :group="group" class="v-treeview v-treeview-draggable" :class="themeClassName" ghost-class="ghost" @input="updateValue">
        <draggable-tree-view-node v-for="(item, index) in value" :key="item[itemKey ? itemKey : index]" :item-key="itemKey" :children="children" :group="group" :value="item" @input="updateItem" @click="click">
            <template v-slot:prepend="{ item, open }">
                <slot name="prepend" v-bind="{ item, open }"> </slot>
            </template>
            <template v-slot:label="{ item, open }">
                <slot name="label" v-bind="{ item, open }"> </slot>
            </template>
            <template v-slot:append="{ item, open }">
                <slot name="append" v-bind="{ item, open }"> </slot>
            </template>
        </draggable-tree-view-node>
    </draggable>
</template>

<script>
import Vue, { PropType } from "vue";
import draggable from "vuedraggable";
import DraggableTreeViewNode from "./DraggableTreeviewNode.vue";

export default Vue.extend({
    components: {
        draggable,
        DraggableTreeViewNode,
    },
    props: {
        dense: { type: Boolean, default: false },
        itemKey: { type: String, default: "" },
        children: { type: String, default: "children" },
        value: {
            type: Array,
            default: [],
        },
        group: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            localValue: [...this.value],
        };
    },
    computed: {
        themeClassName() {
            return {
                "theme--light": !this.$vuetify.theme.isDark,
                "theme--dark": this.$vuetify.theme.isDark,
                "v-treeview--dense": this.dense,
            };
        },
    },
    watch: {
        value(value) {
            this.localValue = [...value];
        },
    },
    methods: {
        click(value) {
            this.$emit("click", value);
        },
        updateValue(value) {
            this.localValue = value;
            this.$emit("input", this.localValue);
        },
        updateItem(itemValue) {
            const index = this.localValue.findIndex((v) => v.id === itemValue.id);
            this.$set(this.localValue, index, itemValue);
            this.$emit("input", this.localValue);
        },
    },
});
</script>
