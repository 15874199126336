<template>
    <v-card tile flat class="contents-card-advertisement" @click="go">
        <!-- image -->
        <v-card tile :img="value?.image?.src" class="contents-card-advertisement__image">
            <v-responsive :aspect-ratio="1 / 1" />
            <v-chip color="rgba(0,0,0,0.2)" x-small class="white--text">광고</v-chip>
        </v-card>
        <!-- // image -->
        <v-sheet v-if="value.content" class="pa-18px">
            <div v-html="value.content" />
        </v-sheet>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    methods: {
        go() {
            if(this.value?.isOuterLink) {
                window.open(this.value?.url, "_self");
            } else {
                this.$router.push(this.value?.url);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.contents-card-advertisement {
    &__image {
        position: relative;
        .v-chip {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
}
</style>