var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$slots['tit'] ? _c('div', {
    staticClass: "tit-wrap",
    class: this.$slots['subTit'] || _vm.titAlign != undefined ? _vm.titAlign : 'text-left'
  }, [_c('h2', {
    staticClass: "tit",
    class: [this.$slots['subTit'] ? 'd-sm-inline-flex align-center' : '', _vm.titSize ? 'tit--' + _vm.titSize : '']
  }, [_vm._t("tit"), this.$slots['subTit'] ? _c('v-divider', {
    staticClass: "h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block",
    attrs: {
      "vertical": ""
    }
  }) : _vm._e(), this.$slots['subTit'] ? _c('span', {
    staticClass: "d-block d-sm-inline-block txt txt txt--sm txt--dark pt-8px pt-sm-0"
  }, [_vm._t("subTit")], 2) : _vm._e()], 2), this.$slots['txt'] ? _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135"
  }, [_vm._t("txt")], 2) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }