import axios from "@/plugins/axios";

let url = "/api/v1/promoter/bonuses";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
};
