import { initBank } from "./common.inits";

export const initBankAction = (action = {}) => ({
    ...action,

    bank: initBank(action?.bank),
});

export const initBankHoldVerification = (verification = {}) => ({
    ...initBankAction(verification),

    sendAmount: verification?.sendAmount || null,
    senderName: verification?.senderName || null,
    attemptCount: verification?.attemptCount || 0,

    code: null,

    isSuccess: verification?.isSuccess || false,
    isOutDated: verification?.isOutDated || false,
});
