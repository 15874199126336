var render = function render(){
  var _vm$value, _vm$value$image;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "contents-card-advertisement",
    attrs: {
      "tile": "",
      "flat": ""
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-card', {
    staticClass: "contents-card-advertisement__image",
    attrs: {
      "tile": "",
      "img": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$image = _vm$value.image) === null || _vm$value$image === void 0 ? void 0 : _vm$value$image.src
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }), _c('v-chip', {
    staticClass: "white--text",
    attrs: {
      "color": "rgba(0,0,0,0.2)",
      "x-small": ""
    }
  }, [_vm._v("광고")])], 1), _vm.value.content ? _c('v-sheet', {
    staticClass: "pa-18px"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.value.content)
    }
  })]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }