var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-badge', {
    attrs: {
      "value": _vm.isShow,
      "dot": _vm.isMobile,
      "color": "accent2",
      "content": _vm.notiCount
    }
  }, [_c('button', {
    staticClass: "header-icon-menu__link header-icon-menu__link--notification",
    on: {
      "click": _vm.shows
    }
  }, [_c('div', {
    staticClass: "header-icon"
  }), _c('span', {
    staticClass: "header-icon-text"
  }, [_vm._v("알림")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }