var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `v-treeview-node v-treeview-node--click ${_vm.hasChildren ? '' : 'v-treeview-node--leaf'}`
  }, [_c('div', {
    staticClass: "v-treeview-node__root",
    on: {
      "click": _vm.select
    }
  }, [_vm._l(_vm.appendLevel, function (index) {
    return _c('div', {
      key: index,
      staticClass: "v-treeview-node__level"
    });
  }), _vm.hasChildren ? _c('i', {
    staticClass: "v-icon notranslate v-treeview-node__toggle v-icon--link mdi mdi-menu-down",
    class: {
      'v-treeview-node__toggle--open': _vm.open,
      'theme--dark': _vm.isDark,
      'theme--light': !_vm.isDark
    },
    attrs: {
      "role": "button"
    }
  }) : _vm._e(), _vm._t("prepend", null, null, {
    item: _vm.value,
    open: _vm.open
  }), _c('div', {
    staticClass: "v-treeview-node__label"
  }, [_vm._t("label", function () {
    return [_vm._v(" " + _vm._s(_vm.value.name) + " ")];
  }, null, {
    item: _vm.value,
    open: _vm.open
  })], 2), _vm._t("append", null, null, {
    item: _vm.value
  })], 2), _vm.open ? _c('div', {
    staticClass: "v-treeview-node__children v-treeview-node__children__draggable"
  }, [_c('draggable', {
    attrs: {
      "group": _vm.group,
      "value": _vm.value[_vm.children],
      "ghost-class": "ghost"
    },
    on: {
      "input": _vm.updateValue
    }
  }, _vm._l(_vm.value[_vm.children], function (child, index) {
    return _c('treeview-node', {
      key: child[_vm.itemKey ? _vm.itemKey : index],
      attrs: {
        "children": _vm.children,
        "item-key": _vm.itemKey,
        "group": _vm.group,
        "value": child,
        "level": _vm.level + 1
      },
      on: {
        "input": _vm.updateChildValue,
        "click": _vm.click
      },
      scopedSlots: _vm._u([{
        key: "prepend",
        fn: function (_ref) {
          var item = _ref.item,
            open = _ref.open;
          return [_vm._t("prepend", null, null, {
            item,
            open
          })];
        }
      }, {
        key: "append",
        fn: function (_ref2) {
          var item = _ref2.item;
          return [_vm._t("append", null, null, {
            item
          })];
        }
      }], null, true)
    });
  }), 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }