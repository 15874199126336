var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "categories-mobile"
  }, [_c('v-tabs', {
    attrs: {
      "grow": "",
      "center-active": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }, [_c('v-tab', {
    attrs: {
      "exact": "",
      "to": "/shop/products",
      "tab-value": null
    }
  }, [_vm._v("전체")]), _vm._l(_vm.categories, function (_ref) {
    var _id = _ref._id,
      name = _ref.name,
      code = _ref.code;
    return [_c('v-tab', {
      key: _id,
      attrs: {
        "tab-value": code,
        "exact": "",
        "to": {
          path: '/shop/products',
          query: Object.assign({}, _vm.$route.query, {
            category: code
          })
        }
      },
      on: {
        "!click": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.go(code);
        }
      }
    }, [_vm._v(_vm._s(name))])];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }