<template>
    <div v-if="this.$slots['tit']" class="tit-wrap" :class="this.$slots['subTit'] || titAlign != undefined ? titAlign : 'text-left'">
        <h2 class="tit" :class="[this.$slots['subTit'] ? 'd-sm-inline-flex align-center' : '', titSize ? 'tit--' + titSize : '']">
            <slot name="tit" />
            <v-divider v-if="this.$slots['subTit']" vertical class="h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block" />
            <span v-if="this.$slots['subTit']" class="d-block d-sm-inline-block txt txt txt--sm txt--dark pt-8px pt-sm-0"><slot name="subTit" /></span>
        </h2>
        <p v-if="this.$slots['txt']" class="txt txt--sm txt--dark line-height-135"><slot name="txt" /></p>
    </div>
</template>

<script>
export default {
    props: {
        tit: String,
        subtit: String,
        txt: String,
        titAlign: String,
        titSize: String,
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
