import axios from "@/plugins/axios";
import categories from "./categories";

let url = "/api/v1/shop/displays";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    categories,
};
