import axios from "@/plugins/axios";

let url = "/api/console/shop/invoices";

export default {
    // getBoilerplates(data){
    //     return axios.get(url, data).then(result => result.data);
    // },
    // getBoilerplate(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    // postBoilerplate(data){
    //     return axios.post(url, data).then(result => result.data);
    // },
    putInvoice(data){
        return axios.put(`${url}/${data.purchaseNo}`, data).then(result => result.data);
    },
    // deleteBoilerplate(data){
    //     return axios.delete(`${url}/${data._id}`).then(result => result.data);
    // }
}
