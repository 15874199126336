export const USER_LEVEL_CRITERIA_TYPES = {
    PRODUCT_PURCHASE_AMOUNT: { value: "PRODUCT_PURCHASE_AMOUNT", text: "상품구매금액" },
};
export const USER_LEVEL_DISCOUNT_TYPES = {
    PRODUCT_PURCHASE_AMOUNT: { value: "PRODUCT_PURCHASE_AMOUNT", text: "상품구매금액" },
};
export const USER_LEVEL_DISCOUNT_UNITS = {
    AMOUNT: { value: "AMOUNT", text: "금액", suffix: "원" },
    PERCENT: { value: "PERCENT", text: "비율", suffix: "%", min: 0, max: 100 },
};
