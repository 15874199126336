export const initShopBulkUploadData = (data = {}) => ({
    ...data,

    categories: data?.categories ?? [],
    brands: data?.brands ?? [],
    products: data?.products ?? [],
    options: data?.options ?? [],
    choicees: data?.choicees ?? [],
    relateds: data?.relateds ?? [],
    bundleds: data?.bundleds ?? [],
});
