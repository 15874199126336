export const initShowroomItem = (item = {}) => ({
    ...item,

    tempId: item.tempId || Math.random(),

    _product: item._product || null,

    name: item.name || null,
    desc: item.desc || null,
    href: item.href || null,

    x: item.x || null,
    y: item.y || null,
});

export const initShowroom = (showroom = {}) => ({
    ...showroom,

    _image: showroom._image || null,
    image: showroom.image || null,

    code: showroom.code || null,

    index: showroom.index || null,
    shows: showroom.shows || false,

    startsAt: showroom.startsAt || null,
    endsAt: showroom.endsAt || null,

    ratio: {
        ...(showroom.ratio || {}),
        width: showroom.ratio?.width || 1,
        height: showroom.ratio?.height || 1,
    },

    items: [...(showroom.items || []).map(initShowroomItem)],
});
