<template>
    <v-sheet v-bind="$attrs">
        <slot v-bind="{ loading, categories }" />
    </v-sheet>
</template>

<script>
import api from "@/api";

export default {
    props: {
        code: { type: String, default: null },

        skip: { type: [String, Number], default: undefined },
        limit: { type: [String, Number], default: undefined },
    },
    data: () => ({
        categories: [],
        loading: false,
    }),
    computed: {
        products() {
            return this.categories.map(({ product } = {}) => product).filter((item) => !!item);
        },
        headers() {
            const { skip, limit } = this;
            return { skip, limit };
        },
        params() {
            const { code } = this;
            return { code };
        },
    },
    mounted() {
        if (this.code) this.init();
    },
    watch: {
        params() {
            if (this.code) this.init();
        },
        loading(loading) {
            this.$emit("setLoading", loading);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { headers, params, code } = this;
                const { categories } = await api.v1.shop.displays.categories.gets({
                    code,
                    headers,
                    params,
                });
                this.categories = categories;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
