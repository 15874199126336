import axios from "@/plugins/axios";

let url = "/api/v1/promoter/bank/hold-verifications";

export default {
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    putVerify(data) {
        return axios.put(`${url}/${data._id}/verify`, data).then((result) => result.data);
    },
};
