var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_vm.banner ? _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, _vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (value, index) {
    return _c('contents-card-banner', {
      key: index,
      attrs: {
        "value": value
      }
    });
  }), 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": _vm.banner ? 7 : 12
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('contents-card-tags')], 1), !_vm.isMobile ? [_vm._l(_vm.advertisements, function (advertisement, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('contents-card-advertisement', _vm._b({}, 'contents-card-advertisement', {
      value: advertisement
    }, false))], 1)];
  })] : _vm._e()], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('contents-card-rank')], 1), !_vm.isMobile ? [_vm._l(_vm.stores, function (store, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('recommend-store-item', _vm._b({}, 'recommend-store-item', {
      value: store
    }, false))], 1)];
  })] : _vm._e()], 2)], 1), _vm.isMobile ? [_vm._l(_vm.advertisements, function (advertisement, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('contents-card-advertisement', _vm._b({}, 'contents-card-advertisement', {
      value: advertisement
    }, false))], 1)];
  }), _vm._l(_vm.stores, function (store, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('recommend-store-item', _vm._b({}, 'recommend-store-item', {
      value: store
    }, false))], 1)];
  })] : _vm._e()], 2)], 1), _c('contents-load-banners', {
    model: {
      value: _vm.banner,
      callback: function ($$v) {
        _vm.banner = $$v;
      },
      expression: "banner"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }