import Vue from 'vue';
import VuetifyDraggableTreeview from '../components/plugins/vuetify/vuetify-draggable-treeview';

Vue.use(VuetifyDraggableTreeview)

// or manually import
VuetifyDraggableTreeview

export default Vue.extend({
    components: {
        VuetifyDraggableTreeview
    }
})
