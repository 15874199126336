import bank from "./bank";
import bonuses from "./bonuses";
import promotees from "./promotees";
import purchases from "./purchases";
import settlements from "./settlements";

export default {
    bank,
    bonuses,
    promotees,
    purchases,
    settlements,
};
