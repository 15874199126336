var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-bottom-navigation', {
    staticClass: "button-navigation button-navigation--bottom"
  }, [_vm._l(_vm.items, function (item, index) {
    return [item.type ? [_c('v-btn', {
      key: index,
      attrs: {
        "color": "#fff"
      },
      on: {
        "click": function ($event) {
          return _vm.access(item.type);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c('div', {
      staticClass: "navigation-icon",
      class: `navigation-icon--${item.icon}`
    })])] : [_c('v-btn', {
      key: index,
      attrs: {
        "color": "#fff",
        "to": item.link
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "navigation-icon",
      class: `navigation-icon--${item.icon}`
    })])]];
  })], 2), _c('v-card', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "button-navigation button-navigation--aside"
  }, [_vm._l(_vm.items, function (item, index) {
    return [item.type ? [_c('v-btn', {
      key: index,
      attrs: {
        "color": "#fff"
      },
      on: {
        "click": function ($event) {
          return _vm.access(item.type);
        }
      }
    }, [_c('div', {
      staticClass: "navigation-icon",
      class: `navigation-icon--${item.icon}`
    }), _vm._v(" " + _vm._s(item.name) + " ")])] : [_c('v-btn', {
      key: index,
      attrs: {
        "color": "#fff",
        "to": item.link
      }
    }, [_c('div', {
      staticClass: "navigation-icon",
      class: `navigation-icon--${item.icon}`
    }), _vm._v(" " + _vm._s(item.name) + " ")])]];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }