import CookieStorage from "@/plugins/cookie-storage";
import { getCurrentPosition } from "@/plugins/getCurrentPosition";

const cookieStorage = new CookieStorage();

const kakao = window?.kakao?.maps;
const { Geocoder, Status, Places } = kakao?.services || {};
const geocoder = Geocoder ? new Geocoder() : null;
const place = Places ? new Places() : null;

export const location = {
    namespaced: true,
    state: {
        lat: 37.4835924256371,
        lng: 127.032693842117,
        lvl: 7,
        bounds: { w: null, s: null, e: null, n: null },

        sido: null,
        gugun: null,
        isGPSAllowed: false
    },
    mutations: {
        setSido(state, sido) {
            state.sido = sido;
        },
        setGugun(state, gugun) {
            state.gugun = gugun;
        },
        setLvl(state, { lvl }) {
            state.lvl = +lvl;
        },
        setBounds(state, { w = null, s = null, e = null, n = null } = {}) {
            state.bounds = { w, s, e, n };
        },
        setCoordinates(state, { lat, lng }) {
            state.lat = +lat;
            state.lng = +lng;
        },
        setIsGPSAllowd(state, isGPSAllowed) {
            cookieStorage.setItem("isGPSAllowed", isGPSAllowed);
            state.isGPSAllowed = isGPSAllowed;
        },
    },
    actions: {
        async getSidoGugun({ state, commit }, { lng, lat, callback = () => {} }) {
            try {
                // // 주소 셋업
                geocoder?.coord2RegionCode?.(lng, lat, (results, status) => {
                    let sido = results?.[0]?.region_1depth_name;
                    if (sido.includes("북도") || sido.includes("남도")) sido = sido[0] + sido[2];
                    sido = sido.slice(0, 2);
                    commit("setSido", sido);
                    commit("setGugun", results?.[0]?.region_2depth_name.split(" ")[0]);
                    callback();
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getCurrentLocation({ state, commit }, { callback = () => {} } = {}) {
            try {
                if (process.env.NODE_ENV == "production" && document.location.protocol != "https:") return;

                // 로케이션 셋업
                const { coords } = await getCurrentPosition();
                const { latitude: lat, longitude: lng } = coords;
                commit("setCoordinates", { lat, lng });
                commit("setLvl", { lvl: 7 });
                commit("setIsGPSAllowd", true);

                // // 주소 셋업
                geocoder?.coord2RegionCode?.(lng, lat, (results, status) => {
                    commit("setSido", results?.[0]?.region_1depth_name);
                    commit("setGugun", results?.[0]?.region_2depth_name);
                    callback();
                });
            } catch (error) {
                commit("setIsGPSAllowd", false);
                const noti = cookieStorage.getItem("noti");
                // 노티가 없거나 만료되었을 경우
                if (!noti || JSON.parse(noti).expires < new Date().getTime()) {
                    console.error(error);
                    alert("위치 권한을 허용하신 뒤 다시 시도하세요");
                    cookieStorage.setItem("noti", JSON.stringify({ value: "noti", expires: new Date().getTime() + 1000 * 60 * 60 * 24 }));
                }
            }
        },
        async getAddressLocation({ commit }, { location = "", callback = () => {} }) {
            geocoder?.addressSearch?.(location, (results, status) => {
                if (status === Status.OK) {
                    commit("setSido", results?.[0]?.address?.region_1depth_name);
                    commit("setGugun", results?.[0]?.address?.region_2depth_name);
                    commit("setCoordinates", { lat: parseFloat(results?.[0]?.y), lng: parseFloat(results?.[0]?.x) });
                    callback(results);
                } else alert("위치를 확인할 수 없습니다");
            });
        },

        async getAddressByKeyword({ commit }, { keyword = "", callback = () => {} }) {
            place?.keywordSearch?.(keyword, (results, status) => {
                if (status === Status.OK) {
                    commit("setCoordinates", { lat: parseFloat(results?.[0]?.y), lng: parseFloat(results?.[0]?.x) });
                    callback(results?.[0]);
                } else alert("위치를 확인할 수 없습니다");
            });
        },
    },
    getters: {
        kakaomapCenterPosition: ({ lng, lat }) => {
            return { lng, lat };
        },
        geolocation: ({ lng, lat }) => ({
            type: "Point",
            coordinates: [lng, lat],
        }),
        geometry: ({ bounds: { w, s, e, n } }) => {
            if (!!w && !!s && !!e && !!n) {
                return {
                    type: "Polygon",
                    coordinates: [
                        [
                            [w, s],
                            [w, n],
                            [e, n],
                            [e, s],
                            [w, s],
                        ],
                    ],
                };
            } else return null;
        },
    },
};

export default location;
