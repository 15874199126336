import axios from "@/plugins/axios";

let url = "/api/plugins/nice/pay";

export const pay = {
    config: {
        get(data) {
            return axios.get(`${url}/config`, data).then((result) => result.data);
        },
    },
    generate(data) {
        return axios.post(`${url}/generate`, data).then((result) => result.data);
    },
    approval(data) {
        return axios.post(`${url}/approval`, data).then((result) => result.data);
    },
};
