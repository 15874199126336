import { USER_TYPES } from "../constants";

export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    nickname: user?.nickname || null,
    email: user?.email || null,
    phone: user?.phone || null,

    referrer: user?.referrer || null,
    promoterCode: user?.promoterCode || null,

    type: user?.type ?? USER_TYPES.PERSON.value,

    bank: {
        ...(user?.bank || {}),
        code: user?.bank?.code || null,
        accountHolder: user?.bank?.accountHolder || null,
        accountNumber: user?.bank?.accountNumber || null,
    },

    meta: {
        ...user?.meta,
        isReceiveEmail: user?.meta?.isReceiveEmail || false,
        isReceiveSMS: user?.meta?.isReceiveSMS || false,
        isReceiveAds: user?.meta?.isReceiveAds || false,
    },

    memo: user?.memo || null,

    // 개인회원
    gender: user?.gender || "MALE",
    birthday: user?.birthday || null,

    // 기업회원
    businessRegistration: user?.businessRegistration || null,
    _businessRegistration: user?._businessRegistration || null,

    mainBanner: user?.mainBanner || null,
    _mainBanner: user?._mainBanner || null,

    companyName: user?.companyName || null,
    ceoName: user?.ceoName || null,
    licenseCode: user?.licenseCode || null,

    department: user?.department || null,
    tel: user?.tel || null,
});
