<template>
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-person">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C7.70285 1 5.84064 2.86221 5.84064 5.15936C5.84064 7.45652 7.70285 9.31873 10 9.31873C12.2972 9.31873 14.1594 7.45652 14.1594 5.15936C14.1594 2.86221 12.2972 1 10 1ZM7.70519 5.15936C7.70519 3.89197 8.73261 2.86455 10 2.86455C11.2674 2.86455 12.2948 3.89197 12.2948 5.15936C12.2948 6.42675 11.2674 7.45417 10 7.45417C8.73261 7.45417 7.70519 6.42675 7.70519 5.15936ZM10 9.87451C5.47502 9.87451 1.80678 13.5427 1.80678 18.0677V18.4711C1.80678 18.7632 2.04358 19 2.33567 19H3.14244C3.43452 19 3.67133 18.7632 3.67133 18.4711V18.0677C3.67133 14.5725 6.50478 11.7391 10 11.7391C13.4952 11.7391 16.3287 14.5725 16.3287 18.0677V18.4711C16.3287 18.7632 16.5655 19 16.8576 19H17.6643C17.9564 19 18.1932 18.7632 18.1932 18.4711V18.0677C18.1932 13.5427 14.525 9.87451 10 9.87451Z" />
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-person {
    width: 20px;
}
</style>
