var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-icon-menu"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "header-icon-menu__link header-icon-menu__link--home",
    attrs: {
      "to": _vm.accessToken ? `/minishop/${_vm._user}` : '/login'
    }
  }, [_c('div', {
    staticClass: "header-icon"
  }), _c('span', {
    staticClass: "header-icon-text"
  }, [_vm._v("미니홈")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('main-header-notification')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "header-icon-menu__link header-icon-menu__link--shopping-bag",
    attrs: {
      "to": "/cart"
    }
  }, [_c('div', {
    staticClass: "header-icon"
  }), _c('span', {
    staticClass: "header-icon-text"
  }, [_vm._v("장바구니")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('header-search')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }