var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    staticClass: "v-treeview v-treeview-draggable",
    class: _vm.themeClassName,
    attrs: {
      "value": _vm.localValue,
      "group": _vm.group,
      "ghost-class": "ghost"
    },
    on: {
      "input": _vm.updateValue
    }
  }, _vm._l(_vm.value, function (item, index) {
    return _c('draggable-tree-view-node', {
      key: item[_vm.itemKey ? _vm.itemKey : index],
      attrs: {
        "item-key": _vm.itemKey,
        "children": _vm.children,
        "group": _vm.group,
        "value": item
      },
      on: {
        "input": _vm.updateItem,
        "click": _vm.click
      },
      scopedSlots: _vm._u([{
        key: "prepend",
        fn: function (_ref) {
          var item = _ref.item,
            open = _ref.open;
          return [_vm._t("prepend", null, null, {
            item,
            open
          })];
        }
      }, {
        key: "label",
        fn: function (_ref2) {
          var item = _ref2.item,
            open = _ref2.open;
          return [_vm._t("label", null, null, {
            item,
            open
          })];
        }
      }, {
        key: "append",
        fn: function (_ref3) {
          var item = _ref3.item,
            open = _ref3.open;
          return [_vm._t("append", null, null, {
            item,
            open
          })];
        }
      }], null, true)
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }