<template>
    <div>
        <div class="tit-wrap tit-wrap--lg text-center">
            <h2 class="tit">모아고 추천 상품</h2>
        </div>
        <v-row class="row--lg" justify="center">
            <template v-for="value in displays.slice(0, 3)">
                <v-col cols="12" lg="4" :key="value._id">
                    <contents-card-story v-bind="{ value }" />
                </v-col>
            </template>
        </v-row>

        <contents-load-displays v-model="displays"/>
    </div>
</template>

<script>
import ContentsCardStory from "./contents/contents-card-story.vue";
import ContentsLoadDisplays from "./contents/contents-load-displays.vue";

export default {
    components: {
        ContentsCardStory,
        ContentsLoadDisplays,
    },
    data: () => ({
        displays: [],
    }),
};
</script>