import axios from "@/plugins/axios";
import router from "../router";

import auth from "./auth";
import console from "./console";
import editor from "./editor";
import { plugins } from "./plugins";
import v1 from "./v1";

export default {
    auth,
    console,
    editor,
    plugins,
    v1,
    getResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => new File([result.data], isShort ? filename?.split("/")?.pop() : filename));
    },
};
