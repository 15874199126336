export const initForm = (form = {}) => ({
    ...form,

    _files: [...(form._files || [])],
    files: [...(form.files || [])],

    code: form.code || null,
    subject: form.subject || null,
    content: form.content || null,
    category: form.category || null,

    writer: {
        ...(form.writer || {}),
        name: form.writer?.name || null,
        phone: form.writer?.phone || null,
    },

    meta: {
        ...(form.meta || {}),
        _terms: form.meta?._terms || [],
    },
});
