<template>
    <div>
        <!-- Mobile -->
        <v-bottom-navigation class="button-navigation button-navigation--bottom">
            <template v-for="(item, index) in items">
                <template v-if="item.type">
                    <v-btn :key="index" color="#fff" @click="access(item.type)">
                        {{ item.name }}
                        <div class="navigation-icon" :class="`navigation-icon--${item.icon}`" />
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn :key="index" color="#fff" :to="item.link">
                        <span>
                            {{ item.name }}
                        </span>
                        <div class="navigation-icon" :class="`navigation-icon--${item.icon}`" />
                    </v-btn>
                </template>
            </template>
        </v-bottom-navigation>
        <!-- // Mobile -->

        <!-- PC -->
        <v-card v-scroll="onScroll" class="button-navigation button-navigation--aside">
            <template v-for="(item, index) in items">
                <template v-if="item.type">
                    <v-btn :key="index" color="#fff" @click="access(item.type)">
                        <div class="navigation-icon" :class="`navigation-icon--${item.icon}`" />
                        {{ item.name }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn :key="index" color="#fff" :to="item.link">
                        <div class="navigation-icon" :class="`navigation-icon--${item.icon}`" />
                        {{ item.name }}
                    </v-btn>
                </template>
            </template>
        </v-card>
        <!-- // PC -->
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "마이페이지",
                icon: "mypage",
                type: "mypage",
            },
            {
                name: "미니홈",
                icon: "minihome",
                type: "mini",
            },
            {
                name: "고객센터",
                icon: "cscenter",
                link: "/center/notice",
            },
            {
                name: "장바구니",
                icon: "cart",
                type: "cart",
            },
            {
                name: "스토리",
                icon: "story",
                link: "/story/bicycle",
            },
        ],
    }),
    computed: {
        _user() {
            return this.$store.state.payload._user;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    methods: {
        onScroll: function () {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const asideButtonGroup = document.querySelector(".button-navigation--aside");

            if (asideButtonGroup !== null) {
                if (scrollTop > window.innerHeight / 4) {
                    asideButtonGroup.classList.add("active");
                } else {
                    asideButtonGroup.classList.remove("active");
                }
            }
        },
        access(type) {
            if (!this.accessToken) this.$router.push({ path: "/login" });
            else {
                switch (type) {
                    case "mypage":
                        this.$router.push({ path: `/mypage/dashboard` });
                        break;
                    case "mini":
                        this.$router.push({ path: `/minishop/${this._user}` });
                        break;
                    case "cart":
                        this.$router.push({ path: "/cart" });
                        break;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// common
.navigation-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
    transition: all ease-out 0.2s;
    &--mypage {
        background-image: url("/images/icon/icon-mypage.svg");
    }
    &--minihome {
        background-image: url("/images/icon/icon-minihome.svg");
    }
    &--cscenter {
        background-image: url("/images/icon/icon-cscenter.svg");
    }
    &--cart {
        background-image: url("/images/icon/icon-cart.svg");
    }
    &--story {
        background-image: url("/images/icon/icon-story.svg");
    }
}
.button-navigation {
    font-family: var(--font-secondary);
    z-index: 100;
    .v-btn {
        font-size: var(--txt-font-size-xs) !important;
    }
}

// mobile
.button-navigation--bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70px !important;
    padding-top: 12px;
    .v-btn {
        width: calc(100% / 5);
        height: 46px !important;
        &::before {
            display: none;
        }
    }
}

// PC (aside)
.button-navigation--aside {
    display: none;
}
@media (min-width: 1024px) {
    .button-navigation--bottom {
        display: none;
    }
    .button-navigation--aside {
        display: block;
        position: fixed;
        right: 12px;
        top: 50%;
        width: 62px;
        border-radius: 50px !important;
        border: 1px solid var(--border-color);
        box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
        transform: translateY(-40%);
        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(-50%);
        }
        .v-btn {
            font-weight: 600;
            font-size: 10px !important;

            min-width: initial;
            height: 62px !important;
            padding: 0;
            width: 100%;
            border-bottom: 1px solid;
            border-color: var(--border-color) !important;
            border-radius: 0;
            &:first-child {
                border-radius: 50px 50px 0 0;
            }
            &:last-child {
                border-radius: 0 0 999px 999px;
                border: none;
            }
            ::v-deep() {
                .v-btn__content {
                    flex-direction: column !important;
                }
            }
        }
    }
}
@media (min-width: 1800px) {
    .button-navigation--aside {
        right: 120px;
        .v-btn {
            &:hover {
                .navigation-icon {
                    &--mypage {
                        background-image: url("/images/icon/icon-mypage-hover.svg");
                    }
                    &--minihome {
                        background-image: url("/images/icon/icon-minihome-hover.svg");
                    }
                    &--cscenter {
                        background-image: url("/images/icon/icon-cscenter-hover.svg");
                    }
                    &--cart {
                        background-image: url("/images/icon/icon-cart-hover.svg");
                    }
                    &--story {
                        background-image: url("/images/icon/icon-story-hover.svg");
                    }
                }
            }
        }
    }
}
</style>