<template>
    <div v-if="previewProduct.product">
        <v-avatar size="62" class="aside-button-product" v-scroll="onScroll" @click="toggleShows">
            <v-img :src="previewProduct?.product?.thumb?.url"></v-img>
        </v-avatar>
        <template v-if="shows">
            <v-avatar v-for="(item, index) in productItems" :key="index" size="62" class="aside-button-item" color="white" :style="{ marginRight: `${index * 80}px` }" @click="$router.push(`/shop/products/${item._product}`)">
                <v-img :src="item?.product?.thumb?.url">
                    <template #placeholder>
                        <v-icon color="grey lighten-2">mdi-image-broken-variant</v-icon>
                    </template>
                </v-img>
            </v-avatar>
            <v-avatar size="62" class="aside-button-item" color="rgba(255,255,255,0.7)" :style="{ marginRight: `${productItems.length * 80}px` }" @click="shows = false">
                <v-icon> mdi-close </v-icon>
            </v-avatar>
        </template>
    </div>
</template>

<script>
export default {
    data: () => ({
        type: "number",
        number: 0,
        duration: 50,
        offset: 0,
        easing: "easeInOutCubic",
        shows: false,
    }),
    computed: {
        previewProduct() {
            return this.$store.state.watches[0];
        },
        productItems() {
            return this.$store.state.watches.slice(1, 4);
        },
    },
    methods: {
        onScroll: function () {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const asideButtonGroup = document.querySelector(".aside-button-product");
            if (asideButtonGroup !== null) {
                if (scrollTop > window.innerHeight / 4) {
                    asideButtonGroup.classList.add("active");
                } else {
                    asideButtonGroup.classList.remove("active");
                    this.shows = false;
                }
            }
        },
        toggleShows() {
            if (this.shows) this.$router.push(`/shop/products/${this.previewProduct._product}`);
            else this.shows = !this.shows;
        },
    },
};
</script>

<style lang="scss" scoped>
.aside-button-product {
    cursor: pointer;
    position: fixed;
    right: 12px;
    top: calc(50% + 164px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all ease-out 0.2s;
    display: none;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
.aside-button-item {
    cursor: pointer;
    position: fixed;
    right: 12px;
    top: calc(50% + 164px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

@media (min-width: 1024px) {
    .aside-button-product,
    .aside-button-item {
        display: inline-flex;
    }
}
@media (min-width: 1800px) {
    .aside-button-product {
        right: 120px;
    }
}
@media (min-width: 1800px) {
    .aside-button-item {
        right: 200px;
    }
}
</style>