<template>
    <div class="categories-mobile">
        <v-tabs v-model="category" grow center-active show-arrows>
            <v-tab exact to="/shop/products" :tab-value="null">전체</v-tab>
            <template v-for="{ _id, name, code } in categories">
                <v-tab :key="_id" :tab-value="code" exact :to="{ path: '/shop/products', query: { ...$route.query, category: code } }" @click.prevent.stop.capture="go(code)">{{ name }}</v-tab>
            </template>
        </v-tabs>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data: () => ({
        category: null,
    }),
    computed: {
        ...mapState(["categories"]),
    },
    mounted() {
        this.category = this.$route.query.category || null;
    },
    watch: {
        "$route.query.category"(category) {
            this.category = category || null;
        },
    },
    methods: {
        go(category) {
            this.$router.push({ path: "/shop/products", query: { category } });
        },
    },
};
</script>

<style lang="scss" scoped>
.categories-mobile {
    position: absolute;
    top: var(--header-body-height);
    left: 0;
    width: 100%;
    ::v-deep {
        .v-tabs-bar {
            color: var(--v-grey-darken4) !important;
        }
    }
}
</style>
