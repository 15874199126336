var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "header-contents"
  }, [_c('main-logo'), _c('div', {
    staticClass: "header-user-menu"
  }, [_c('ul', [_vm.accessToken ? _c('li', [_c('div', {
    staticClass: "link cursor-pointer",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])]) : _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인")])], 1), _vm.accessToken ? _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_vm._v(" 마이페이지 ")])], 1) : _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/center/notice"
    }
  }, [_vm._v("고객센터")]), _c('ul', {
    staticClass: "header-user-menu__sub"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/center/notice"
    }
  }, [_vm._v("공지사항")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/faq?type=MEMBERSHIP"
    }
  }, [_vm._v("자주 묻는 질문")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/questions"
    }
  }, [_vm._v("1:1 문의")])], 1)])], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/sub/business/about"
    }
  }, [_vm._v("비즈니스")]), _c('ul', {
    staticClass: "header-user-menu__sub"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/about"
    }
  }, [_vm._v("회사소개")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/contact"
    }
  }, [_vm._v("입점신청")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/terms"
    }
  }, [_vm._v("이용약관")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/privacy-policy"
    }
  }, [_vm._v("개인정보 처리방침")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/site-guide"
    }
  }, [_vm._v("이용안내")])], 1)])], 1)])])], 1), _c('v-divider')], 1)], 1), _c('div', {
    staticClass: "header-foot"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('main-header-gnb')], 1)], 1), _c('header-icon-menu')], 1)], 1)]), _c('div', {
    staticClass: "mobile-header"
  }, [_c('main-logo'), _vm._m(0), _c('main-header-notification', {
    staticClass: "mobile-header__notification"
  })], 1), _c('mobile-search-bar'), _c('div', {
    staticClass: "mobile-hamburger"
  }, [_c('button', {
    staticClass: "mobile-hamburger__btn",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('v-sheet', {
    staticClass: "position-relative",
    attrs: {
      "width": "24",
      "height": "24"
    }
  }, [_c('span', {
    staticClass: "mobile-hamburger__line"
  })]), _c('div', {
    staticClass: "font-size-10 line-height-18"
  }, [_vm._v("메뉴")])], 1)]), _c('div', {
    staticClass: "mobile-sidebar"
  }, [_c('div', {
    staticClass: "mobile-sidebar-head"
  }, [_c('router-link', {
    staticClass: "mr-12px",
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-home-white.svg",
      "alt": "홈으로 이동"
    }
  })], 1), _c('div', {
    staticClass: "mobile-sidebar__info"
  }, [!_vm.accessToken ? _c('router-link', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인")]) : _c('a', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])], 1), _c('button', {
    staticClass: "mobile-sidebar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('v-icon', {
    staticClass: "white--text"
  }, [_vm._v("mdi-close")]), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 메뉴바 닫기")])], 1)], 1), _c('div', {
    staticClass: "mobile-sidebar-body"
  }, [_c('main-header-gnb', {
    attrs: {
      "mobile": ""
    }
  })], 1), _c('div', {
    staticClass: "mobile-sidebar-foot"
  }, [_c('main-header-slide'), _c('v-row', {
    staticClass: "row--sm font-",
    attrs: {
      "justify": "center"
    }
  }, [_vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "link cursor-pointer",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])]) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인")])], 1), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_vm._v("마이페이지")])], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/center/notice"
    }
  }, [_vm._v("고객센터")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "mobile-header-dimmed"
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "header-icon-menu__link header-icon-menu__link--search mobile-header__search"
  }, [_c('div', {
    staticClass: "header-icon"
  }), _c('span', {
    staticClass: "header-icon-text"
  }, [_vm._v("검색")])]);

}]

export { render, staticRenderFns }