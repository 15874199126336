import axios from "@/plugins/axios";

let url = "/api/console/shop/islands";

export default {
    getIslands(data){
        return axios.get(url, data).then(result => result.data);
    },
    getIsland(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postIsland(data){
        return axios.post(url, data).then(result => result.data);
    },
    putIsland(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteIsland(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
