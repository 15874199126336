import Vue from "vue";

export const getGeolocationWithKakaoMaps = (address = "") => {
    return new Promise((resolve) => {
        if (kakao && address)
            new kakao.maps.services.Geocoder().addressSearch(address, (result, status) => {
                if (status === kakao.maps.services.Status.OK)
                    resolve({
                        type: "Point",
                        coordinates: [
                            result[0].x, // longitude
                            result[0].y, // latitude
                        ],
                    });
                else resolve(null);
            });
        else resolve(null);
    });
};

export const getGeolocationWithNavigator = () => {
    const { geolocation } = navigator;

    return new Promise((resolve, reject) => {
        if (geolocation)
            geolocation.getCurrentPosition(
                // if success
                (position) =>
                    resolve({
                        type: "Point",
                        coordinates: [
                            position.coords.longitude, //
                            position.coords.latitude, //
                        ],
                    }),

                // if fail
                (error) => {
                    console.error(error);

                    if (error.code === 1) return reject("위치 권한을 허용하신뒤 다시 시도 해 주세요.");
                    else return reject(error.message);
                }
            );
        else return reject("이 브라우저는 GPS를 지원하지 않습니다");
    });
};

export const getDistanceBetweenLocations = (location1, location2) => {
    const [[lon1, lat1] = [], [lon2, lat2] = []] = [location1?.coordinates, location2?.coordinates];
    if (!lon1 || !lat1 || !lon2 || !lat2) throw new Error("위치정보를 다시 입력하세요");

    var R = 6371; // Radius of the earth in km
    var dLon = deg2rad(lon2 - lon1); // deg2rad below
    var dLat = deg2rad(lat2 - lat1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
};

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

const GeolocationMethods = {
    install: function (Vue) {
        Vue.prototype.$getGeolocationWithKakaoMaps = getGeolocationWithKakaoMaps;

        Vue.prototype.$getGeolocationWithNavigator = getGeolocationWithNavigator;

        Vue.prototype.$getDistanceBetweenLocations = getDistanceBetweenLocations;
    },
};

Vue.use(GeolocationMethods);
