var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-display-categories', _vm._b({
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var categories = _ref.categories;
        return [_c('v-chip-group', {
          attrs: {
            "column": ""
          }
        }, [_vm._l(categories, function (category) {
          return [_c('v-chip', {
            key: category._id,
            attrs: {
              "small": "",
              "outlined": "",
              "link": "",
              "color": "grey darken-4",
              "to": {
                path: '/shop/products/',
                query: {
                  groups: _vm.code,
                  category: category.code
                }
              }
            }
          }, [_vm._v("#" + _vm._s(category.name))])];
        })], 2)];
      }
    }])
  }, 'main-display-categories', {
    code: _vm.code
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }