import axios from "@/plugins/axios";

export default {
    getSupplies(_product, data){
        return axios.get(`/api/console/shop/products/${_product}/supplies`, data).then(result => result.data);
    },
    postSupply(_product, data){
        return axios.post(`/api/console/shop/products/${_product}/supplies`, data).then(result => result.data);
    },
    putSupply(_product, data){
        return axios.put(`/api/console/shop/products/${_product}/supplies/${data._id}`, data).then(result => result.data);
    },
    deleteSupply(_product, data){
        return axios.delete(`/api/console/shop/products/${_product}/supplies/${data._id}`).then(result => result.data);
    }
}
