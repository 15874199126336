<template>
    <ul :class="{ 'header-category__lists': pc, 'mobile-category__lists': mobile, 'header-menu__lists': menu }">
        <!-- <li :class="{ 'header-category__list': pc, 'mobile-category__list': mobile, 'header-menu__list': menu }">
            <router-link to="/shop" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                <span>전체보기</span>
                <v-icon v-if="pc">mdi-chevron-right</v-icon>
            </router-link>
        </li> -->

        <li v-for="category in categories" :key="category._id" :class="{ 'header-category__list': pc, 'mobile-category__list': mobile, 'header-menu__list': menu, 'header-menu__list-parents': menu && category.children.length != 0 }">
            <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                <v-icon v-if="pc">mdi-chevron-right</v-icon>
            </span>
            <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                <span>{{ category.name }}</span>
                <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                <v-icon v-if="pc">mdi-chevron-right</v-icon>
            </router-link>

            <div v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--1': pc, 'mobile-category__level': mobile, 'mobile-category__level--1': mobile, 'header-menu__level': menu, 'header-menu__level--1': menu }">
                <div :class="{ 'header-category__level--1__inner': pc, 'header-menu__level--1__inner': menu }">
                    <div v-for="category in category.children" :key="category._id" :class="{ 'header-category__level-list': pc, 'mobile-category__level-list': mobile, 'header-menu__level-list': menu }">
                        <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                            <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                        </span>
                        <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                            <span>{{ category.name }}</span>
                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                        </router-link>

                        <ul v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--2': pc, 'mobile-category__level': mobile, 'mobile-category__level--2': mobile, 'header-menu__level': menu, 'header-menu__level--2': menu }">
                            <li v-for="category in category.children" :key="category._id">
                                <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                                    <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                                    <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                </span>
                                <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                                    <span>{{ category.name }}</span>
                                    <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                </router-link>

                                <ul v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--3': pc, 'mobile-category__level': mobile, 'mobile-category__level--3': mobile, 'header-menu__level': menu, 'header-menu__level--3': menu }">
                                    <li v-for="category in category.children" :key="category._id">
                                        <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                                            <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                        </span>
                                        <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                                            <span>{{ category.name }}</span>
                                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="menu" :class="{ 'header-menu__logo': menu }">
                    <v-row>
                        <template v-for="brand in category?.brands || []">
                            <v-col cols="4" :key="brand?._id" v-if="brand?.label?.url">
                                <v-card tile class="header-menu__logo-img" :style="`background-image: url('${brand?.label?.url}')`" :to="{ path: `/shop/brands/${brand?.code}`, query: { category: category.code } }" />
                            </v-col>
                        </template>
                    </v-row>
                </div>
                <div v-if="menu" :class="{ 'header-menu__img': menu }">
                    <div v-if="category?.visual?.url" class="header-menu__img-img" :style="`background-image: url('${category?.visual?.url}')`"></div>
                </div>
            </div>
        </li>

        <slot />
    </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
        pc: { type: Boolean, default: false },
        mobile: { type: Boolean, default: false },
        menu: { type: Boolean, default: false },
    },
    computed: {
        ...mapState(["categories"]),
    },
};
</script>
