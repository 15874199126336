import { initUsage } from "./common.inits";

export const initUserPoint = (point = {}) => ({
    ...point,

    _user: point._user ?? null,
    _point: point._point ?? null,

    name: point.name ?? null,
    limit: point.limit ?? null,
    amount: point.amount ?? 0,
    amount_left: point.amount_left ?? 0,
    amount_used: point.amount_used ?? 0,

    isUsed: point.isUsed ?? false,
    isExpired: point.isExpired ?? false,
    isExcluded: point.isExcluded ?? false,
    isDisabled: point.isDisabled ?? false,

    usage: initUsage(point.usage),

    usedAt: point.usedAt ?? null,
});
