var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.displays.slice(0, 3), function (value) {
    return [_c('v-col', {
      key: value._id,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('contents-card-story', _vm._b({}, 'contents-card-story', {
      value
    }, false))], 1)];
  })], 2), _c('contents-load-displays', {
    model: {
      value: _vm.displays,
      callback: function ($$v) {
        _vm.displays = $$v;
      },
      expression: "displays"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("모아고 추천 상품")])]);

}]

export { render, staticRenderFns }